import React, { Component } from 'react';
import axios from 'axios';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
	HttpLink,
  useQuery,
  gql
} from "@apollo/client";
import * as Realm from "realm-web";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

// Connect to your MongoDB Realm app
const app = new Realm.App("application-0-qpbks");
// Gets a valid Realm user access token to authenticate requests
async function getValidAccessToken() {
  // Guarantee that there's a logged in user with a valid access token
  if (!app.currentUser) {
    // If no user is logged in, log in an anonymous user. The logged in user will have a valid
    // access token.
    await app.logIn(Realm.Credentials.anonymous());
  } else {
    // An already logged in user's access token might be stale. To guarantee that the token is
    // valid, we refresh the user's custom data which also refreshes their access token.
    await app.currentUser.refreshCustomData();
  }
	console.log(app.currentUser.accessToken);
  return app.currentUser.accessToken;
}

class Product extends Component{

	constructor( props ){
		super( props );
		this.state = {
			products: []
		}
	}
	/**
	 * Get the current address from the default map position and set those values in the state
	 */
	componentDidMount() {
    //console.log(this.props.position);
		//get products
		/*axios.get(`https://southamerica-east1-acougues.cloudfunctions.net/get-products`)
	    .then(res => {
	      const products = res.data;
				//console.log(products);
	      this.setState({ products: products });
				console.log("position");
				console.log(this.props.position);
				//console.log("products");
				//console.log(this.state.products);
	    });*/
	};
	render(){
    console.log("product");
		return(
			<div className="divs-data">
				<div className="divs-bottom">
					<div class="container">
						<div class="col">
							<GetProduct id={this.props.id} />
						</div>
					</div>
				</div>
			</div>
		)
	}
}
const GET_PRODUCT = gql`
	query getProductByID($input: String) {
		getProductByID(input: $input) {
			_id
			price
			title
      city
      state
      country
      seller
      image
		}
	}
`;
/*const client = new ApolloClient({
  uri: 'https://realm.mongodb.com/api/client/v2.0/app/application-0-qpbks/graphql',
	//headers: new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`),
  cache: new InMemoryCache(),
	credentials: 'include',
	fetch: async (uri, options) => {
    const accessToken = await getValidAccessToken();
    options.headers.Authorization = `Bearer ${accessToken}`;
    return fetch(uri, options);
  },
});*/

const client = new ApolloClient({
  link: new HttpLink({
    uri: `https://realm.mongodb.com/api/client/v2.0/app/application-0-qpbks/graphql`,
    // We define a custom fetch handler for the Apollo client that lets us authenticate GraphQL requests.
    // The function intercepts every Apollo HTTP request and adds an Authorization header with a valid
    // access token before sending the request.
    fetch: async (uri, options) => {
      const accessToken = await getValidAccessToken();
      options.headers.Authorization = `Bearer ${accessToken}`;
      return fetch(uri, options);
    },
  }),
  cache: new InMemoryCache()
});

function GetProduct({ id }) {
  console.log("GetProduct ID:"+id);
  const { loading, error, data } = useQuery(GET_PRODUCT, {
    variables: { input: id },
		client,
  });

  if (loading) return null;
  if (error) return `Error! ${error}`;
  console.log(data.getProductByID);
  return (
		<ProductShow product={data.getProductByID} />
  );
}

class ProductShow extends Component{

	constructor( props ){
		super( props );
	}
	componentDidMount() {
	};
	render(){
		console.log(this.props.product);
		return(
			<div className="divs-data">
			 { this.props.product ?
	 			  <div className="product" key={this.props.product._id}>
            <div className="row">
              <div className="col">
                <img className="product-img" src={this.props.product.image}/>
              </div>
            </div>
	 					<div className="row product-sections">
	 						<div className="col-md-4 product-price text-center align-middle autoa">
	 							<h5>{this.props.product.seller}</h5>
                <h6>{this.props.product.city+", "+this.props.product.state+", "+this.props.product.country}</h6>
                <h4><a className="btn btn-outline-dark btn-lg" href="https://grameira.com" role="button"><FontAwesomeIcon icon={faWhatsapp} /> Solicitar orçamento</a></h4>
	 						</div>
	 						<div className="col-md-8 product-detail text-center align-middle autoa">
	 							<h3>{this.props.product.title}</h3>
	 						</div>
	 					</div>
	 				</div>
				: "" }
			</div>
		)
	}
}


export default Product

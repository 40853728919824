import React, { Component } from 'react';
import axios from 'axios';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
	HttpLink,
  useQuery,
  gql
} from "@apollo/client";
import * as Realm from "realm-web";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

// Connect to your MongoDB Realm app
const app = new Realm.App("application-0-qpbks");
// Gets a valid Realm user access token to authenticate requests
async function getValidAccessToken() {
  // Guarantee that there's a logged in user with a valid access token
  if (!app.currentUser) {
    // If no user is logged in, log in an anonymous user. The logged in user will have a valid
    // access token.
    await app.logIn(Realm.Credentials.anonymous());
  } else {
    // An already logged in user's access token might be stale. To guarantee that the token is
    // valid, we refresh the user's custom data which also refreshes their access token.
    await app.currentUser.refreshCustomData();
  }
	console.log(app.currentUser.accessToken);
  return app.currentUser.accessToken;
}

class Products extends Component{

	constructor( props ){
		super( props );
		this.state = {
			products: []
		}
	}
	/**
	 * Get the current address from the default map position and set those values in the state
	 */
	componentDidMount() {
    //console.log(this.props.position);
		//get products
		/*axios.get(`https://southamerica-east1-acougues.cloudfunctions.net/get-products`)
	    .then(res => {
	      const products = res.data;
				//console.log(products);
	      this.setState({ products: products });
				console.log("position");
				console.log(this.props.position);
				//console.log("products");
				//console.log(this.state.products);
	    });*/
	};
	render(){
		return(
			<div className="divs-data">
				<div className="divs-bottom">
					<div class="container">
						<h2 className="text-center">Promoções perto de você</h2>
            <div class="checkbox text-center type-filter">
              <h5>{ this.props.address }</h5>
            </div>
					</div>
					<div class="container">
						<div class="col">
							<NearProducts position={[this.props.position.lat, this.props.position.lng]} />
						</div>
					</div>
				</div>
			</div>
		)
	}
}
const GET_NEAR = gql`
	query getNear($input: [Float]) {
		getNear(input: $input) {
			_id
			price
			title
      city
      state
      country
      seller
      image
		}
	}
`;
/*const client = new ApolloClient({
  uri: 'https://realm.mongodb.com/api/client/v2.0/app/application-0-qpbks/graphql',
	//headers: new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('token')}`),
  cache: new InMemoryCache(),
	credentials: 'include',
	fetch: async (uri, options) => {
    const accessToken = await getValidAccessToken();
    options.headers.Authorization = `Bearer ${accessToken}`;
    return fetch(uri, options);
  },
});*/

const client = new ApolloClient({
  link: new HttpLink({
    uri: `https://realm.mongodb.com/api/client/v2.0/app/application-0-qpbks/graphql`,
    // We define a custom fetch handler for the Apollo client that lets us authenticate GraphQL requests.
    // The function intercepts every Apollo HTTP request and adds an Authorization header with a valid
    // access token before sending the request.
    fetch: async (uri, options) => {
      const accessToken = await getValidAccessToken();
      options.headers.Authorization = `Bearer ${accessToken}`;
      return fetch(uri, options);
    },
  }),
  cache: new InMemoryCache()
});

function NearProducts({ position }) {
  console.log(position);
  const { loading, error, data } = useQuery(GET_NEAR, {
    variables: { input: position },
		client,
  });

  if (loading) return null;
  if (error) return `Error! ${error}`;
  console.log(data.getNear);
  return (
		<ProductList products={data.getNear} />
  );
}

class ProductList extends Component{

	constructor( props ){
		super( props );
	}
	componentDidMount() {
	};
	render(){
		console.log(this.props.products);
    function handleSubmit() {
      document.getElementById("quoteForm").submit();
    }
		return(
			<div className="divs-data">
			 { this.props.products ?
				 this.props.products.map((product) =>
	 			  <div className="product" key={product._id}>
            <div className="row">
              <div className="col">
                <img className="product-img" src={product.image}/>
              </div>
            </div>
	 					<div className="row product-sections">
            <div className="col-md-7 product-detail text-center align-middle autoa">
              <div className="margin-small">
                <h3>{product.title}</h3>
              </div>
            </div>
	 						<div className="col-md-5 product-price text-center align-middle autoa">
                <div className="margin-small">
                  <h6>{product.city+", "+product.state+", "+product.country}</h6>
                  <h4><button type="button" class="btn btn-product btn-outline-dark btn-lg" data-toggle="modal" data-target="#myModal"><FontAwesomeIcon icon={faWhatsapp} /> Solicitar orçamento</button></h4>
                </div>
              </div>
	 					</div>
	 				</div>
	 			)
				: "" }
  <div class="modal fade" id="myModal" role="dialog">
    <div class="modal-dialog">
    
      <div class="modal-content">
        <iframe name="dummyframe" id="dummyframe" style={{display: "none"}}></iframe>
      	<form id="quoteForm" method="post" action="https://app.99inbound.com/api/e/XA4jwZS4" target="dummyframe">
          <div class="modal-header">
          <h4 class="modal-title">Orçamento gratuito</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
          </div>
          <div class="modal-body">
                <label class="form-label">Informações do produto</label>
                <div class="mb-3">
                  <select class="form-control" name="tipo">
                        <optgroup label="Tipo de grama">
                            <option value="Grama Esmeralda" selected>Grama Esmeralda</option>
                            <option value="Grama São Carlos">Grama São Carlos</option>
                            <option value="Grama Bermuda">Grama Bermuda</option>
                        </optgroup>
                  </select>
                </div>
                <div class="mb-3"><input class="form-control" type="number" placeholder="unidades em m²" name="unidades_m2" /></div><small>Marque caso verdadeiro</small>
                <div class="mb-3">
                    <h5 class="text-center"><input type="checkbox" name="clienteInstala" /> Instalação por conta do cliente</h5>
                </div>
                <div class="mb-3"><label class="form-label">Informações da entrega</label></div>
                <div class="mb-3"><select class="form-control" name="estado">
                        <optgroup label="Estado">
                            <option value="Acre" selected>Acre</option>
                            <option value="Alagoas">Alagoas</option>
                            <option value="Amapá">Amapá</option>
                            <option value="Amazonas">Amazonas</option>
                            <option value="Bahia">Bahia</option>
                            <option value="Ceará">Ceará</option>
                            <option value="Distrito Federal">Distrito Federal</option>
                            <option value="Espírito Santo">Espírito Santo</option>
                            <option value="Goiás">Goiás</option>
                            <option value="Maranhão">Maranhão</option>
                            <option value="Mato Grosso">Mato Grosso</option>
                            <option value="Mato Grosso do Sul">Mato Grosso do Sul</option>
                            <option value="Minas Gerais">Minas Gerais</option>
                            <option value="Pará">Pará</option>
                            <option value="Paraíba">Paraíba</option>
                            <option value="Paraná">Paraná</option>
                            <option value="Pernambuco">Pernambuco</option>
                            <option value="Piauí">Piauí</option>
                            <option value="Rio de Janeiro">Rio de Janeiro</option>
                            <option value="Rio Grande do Norte">Rio Grande do Norte</option>
                            <option value="Rio Grande do Sul">Rio Grande do Sul</option>
                            <option value="Rondônia">Rondônia</option>
                            <option value="Roraima">Roraima</option>
                            <option value="Santa Catarina">Santa Catarina</option>
                            <option value="São Paulo">São Paulo</option>
                            <option value="Sergipe">Sergipe</option>
                            <option value="Tocantins">Tocantins</option>
                        </optgroup>
                    </select></div><input class="form-control" type="hidden" name="form_tools_initialize_form" value="1" />
                <div class="mb-3"><input class="form-control" type="text" placeholder="Rua" name="rua" /></div><input class="form-control" type="hidden" name="form_tools_form_id" value="1" />
                <div class="mb-3"><input class="form-control" type="text" placeholder="Número" name="numero" /></div>
                <div class="mb-3"><input class="form-control" type="text" placeholder="Bairro" name="bairro" /></div>
                <div class="mb-3"><input class="form-control" type="text" placeholder="Cidade" name="cidade" /></div>
                <div class="mb-3"><input class="form-control" type="text" placeholder="CEP" name="cep" /></div><input class="form-control d-none invisible" type="text" placeholder="Info" name="info" />
                <div class="mb-3"><label class="form-label">Informações do cliente</label></div>
                <div class="mb-3"><input class="form-control" type="text" placeholder="Nome completo" name="nome" /></div>
                <div class="mb-3"><input class="form-control" type="text" placeholder="CPF ou CNPJ" name="cpf_cnpj" /></div>
                <div class="mb-3"><input class="form-control" type="text" placeholder="WhatsApp (com DDD)" name="whatsapp" /></div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
            <button type="reset" class="btn btn-primary" onClick={handleSubmit} data-dismiss="modal">Pedir orçamento</button>
          </div>
        </form>
      </div>
      
    </div>
  </div>	
			</div>
		)
	}
}


export default Products

import React, { Component } from 'react';
import Map from './Map';
import Product from './Productok';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";

class Home extends Component {
	constructor( props ){
		super( props );
		this.state = {
			posBrasilia: {
				lat: -15.793889,
				lng: -47.882778,
			}
		};
	}
	componentDidMount() {
		navigator.geolocation.getCurrentPosition(position => {
	    this.setState({
				pos: {
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				},
			})
		});
		console.log(this.state.pos);
  }
	/*componentDidUpdate() {
		navigator.geolocation.getCurrentPosition(position => {
	    this.setState({
				pos: {
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				},
			})
		});
		console.log(this.state.pos);
  }*/
	render() {
		/*if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          this.setState({
						pos : {
            	lat: position.coords.latitude,
            	lng: position.coords.longitude,
						}
          });
					console.log(this.state.pos);
        },
        () => {
        }
      );
    } else {
      // Browser doesn't support Geolocation
    }*/
		console.log(this.props.match.params.id);
		return(
			<div>
				<div className="container">
					<div className="divs-header">
						<div className="divs-data">
							<div className="divs-bottom">
								<div class="">
									<div class="row">
										<div class="col-12 vcenter">
											<div className="col divs-logo">
												<img className="logo-img" src="https://i.imgur.com/7CBBRNo.png"></img>
											</div>
											<div className="col divs-button">
												<a className="btn btn-outline-light btn-lg float-right disabled" href="https://criar.usies.com" role="button"><FontAwesomeIcon icon={faMapMarker} />  Anunciar</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{ this.props.match.params.id ? <div className="divs-marker">
							<div class="checkbox text-center">
								<Product
									id={this.props.match.params.id}
								/>
	            </div>
						</div> : "" }
						<div className="divs-marker">
							<h2 className="text-center">Arraste o marcador</h2>
							<div class="checkbox text-center type-filter">
	              <h5>confira as promoções para sua localização</h5>
	            </div>
						</div>
					</div>
					{this.state.pos ? <Map
						google={this.props.google}
						center={this.state.pos}
						height='300px'
						zoom={15}
					/>: "" }
					{!this.state.pos ? <Map
						google={this.props.google}
						center={this.state.posBrasilia}
						height='300px'
						zoom={15}
					/>: "" }
				</div>
				<section className="footer-brand">
        	<div class="container text-center"><a href="https://usies.com"><img className="img-brand" src="https://usies.com/assets/img/usies-logo.png"/></a></div>
    		</section>
			</div>
		);
	}
}

export default Home;
